<template>
  <div class="container mt-5 pt-4 mb-5 pb-4">
    <div v-if="existLast" class="voltar-vitrine mb-3">
      <router-link to="/vitrine-afiliacao"
        ><img src="@/assets/img/icons/arrow-page.svg" />
        {{ $t('views.seller.recruitment.text_1290') }}</router-link
      >
    </div>
    <div v-if="!error_product">
      <div class="box-content">
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-if="!loading && product.affiliation" data-anima="top">
          <div class="flex-content">
            <div class="foto-produto">
              <div class="img">
                <img
                  :src="product.images[0].path"
                  v-if="product.images.length"
                />
              </div>
              <span class="categoria btn-2" v-if="product.category">{{
                product.category.name || ""
              }}</span>
            </div>
            <div class="content-product">
              <div class="content-header">
                <div>
                  <div>
                    <h2>{{ product.name }}</h2>
                    <p v-if="product.seller">por {{ product.seller.name }}</p>
                    <div
                      class="avaliacao"
                      id="avaliacao"
                      v-if="product.rating_count"
                    >
                      <img src="@/assets/img/icons/avaliacao.svg" />
                      {{ Math.floor(product.rating_avg).toFixed(1) }}
                      <span>({{ product.rating_count }})</span>
                    </div>
                    <b-tooltip target="avaliacao" :title="$t('views.seller.recruitment.text_1454')" />
                  </div>
                </div>

                <div
                  class="automatica btn-2"
                  v-if="!product.affiliation_approbation"
                >
                  <img src="@/assets/img/icons/like.svg" />
                  {{ $t('views.seller.recruitment.text_1291') }}
                </div>
              </div>
              <div class="valores">
                <div class="preco">
                  <p>{{ $t('views.seller.recruitment.text_1455') }}</p>
                  <h5>R$ {{ formatMoney(+product.amount) }}</h5>
                </div>
                <div class="comissao">
                  <p>{{ $t('views.seller.recruitment.text_1456') }}</p>
                  <div class="d-flex">
                    <h5>
                      R$
                      {{
                        formatMoney(
                          calComissao(+product.amount, product.comission)
                        )
                      }}
                    </h5>
                    <span>{{ $t('views.seller.recruitment.text_1292') }}</span>
                  </div>
                </div>
              </div>
              <div class="action">
                <p
                  class="mb-3"
                  v-if="affiliation && affiliation.status == 'APPROVED'"
                >
                  {{ $t('views.seller.recruitment.text_1293') }}
                </p>
                <!-- quero me afiliar -->

                <b-form-group
                  label=""
                  label-for="has_manager"
                  v-if="!affiliation && !solicitado"
                >
                  <b-form-checkbox
                    v-model="has_manager"
                    name="has_manager "
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox">
                      {{ $t('views.seller.recruitment.text_1294') }}
                      <span class="descricao-checkbox"
                        >{{ $t('views.seller.recruitment.text_1295') }}</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-input
                  data-anima="top"
                  v-model="manager"
                  v-validate="'required'"
                  autocomplete="off"
                  placeholder="exemplo@exemplo.com"
                  type="text"
                  v-if="has_manager"
                ></b-form-input>
                <!-- quero me afiliar -->
                <BaseButton
                  variant="secondary_outline"
                  class="btn-afiliar mt-3"
                  @click="openConfirmAffiliation"
                  v-if="!affiliation && !solicitado"
                >
                  {{ $t('views.seller.recruitment.text_1296') }}
                </BaseButton>

                <BaseButton
                  variant="secondary_outline"
                  class="btn-afiliar"
                  :disabled="true"
                  v-if="
                    (affiliation && affiliation.status == 'REQUESTED') ||
                      (affiliation && affiliation.status == 'REJECTED') ||
                      solicitado
                  "
                >
                  {{
                    solicitado || affiliation.status == "REQUESTED"
                      ? $t('views.seller.recruitment.text_1297')
                      : $t('views.seller.recruitment.text_1298')
                  }}
                </BaseButton>

                <!-- links de divulgação -->
                <BaseButton
                  variant="secondary_outline"
                  class="btn-afiliar"
                  v-if="affiliation && affiliation.status == 'APPROVED'"
                  @click="
                    toRouter(
                      `/links/?affiliation_id=${affiliation.id}&p_name=${product.name}`
                    )
                  "
                >
                  {{ $t('views.seller.recruitment.text_1299') }}
                </BaseButton>

                <p class="comprar">
                  {{ $t('views.seller.recruitment.text_1457') }}
                  <a :href="link_checkout" target="_blank"> {{ $t('views.seller.recruitment.text_1458') }} </a>
                  {{ $t('views.seller.recruitment.text_1300') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!product.affiliation">
          <div class="d-flex">
            💡
            <div class="ml-3">
              <h6>{{ $t('views.seller.recruitment.text_1301') }}</h6>
              <p>
                {{ $t('views.seller.recruitment.text_1302') }}
              </p>
              <p>{{ $t('views.seller.recruitment.text_1303') }}</p>

              <BaseButton
                variant="info3"
                class="lista-produtos mt-4"
                @click="toRouter('/produtos')"
              >
                {{ $t('views.seller.recruitment.text_1304') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>

      <div class="box-content" v-if="product.affiliation">
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-if="!loading && product.affiliation" data-anima="bottom">
          <div class="sobre-produto">
            <h5> {{ $t('views.seller.recruitment.text_1305') }} </h5>
            <div class="descricao" v-if="product.instructions">
              <v-md-preview
                class="description-mark"
                :text="product.instructions"
              ></v-md-preview>
            </div>
            <div class="descricao mb-4" v-else>
              <p>{{ $t('views.seller.recruitment.text_1306') }}</p>
            </div>
          </div>

          <div class="sobre-produto">
            <h5>{{ $t('views.seller.recruitment.text_1307') }}</h5>
            <div class="descricao">
              <v-md-preview
                class="description-mark"
                :text="product.description"
              ></v-md-preview>
            </div>
            <div class="link-vendas" v-if="product.sales_page">
              <BaseButton
                variant="secondary_outline"
                class="pg-vendas"
                @click="openSalesPage"
              >
                {{ $t('views.seller.recruitment.text_1308') }}
                <img src="@/assets/img/icons/link-externo.svg" />
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error_product">
      <div class="box-content">
        <div class="d-flex">
          💡
          <div class="ml-3">
            <h6>{{ $t('views.seller.recruitment.text_1309') }}</h6>
            <p>
              {{ $t('views.seller.recruitment.text_1310') }}
            </p>

            <BaseButton
              variant="info3"
              class="lista-produtos mt-4"
              @click="$router.push('/')"
            >
              {{ $t('views.seller.recruitment.text_1311') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <ConfirmAffiliation
      :hash_afiliado="hash"
      :product_id="product.id"
      :product_name="product.name"
      :aprovacao="product.affiliation_approbation"
      :manager="has_manager ? manager : ''"
      @close="changeSolicitacao"
    />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
// modal
import ConfirmAffiliation from "@/components/Seller/Affiliation/modals/ConfirmAffiliaton";
// services:
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import GlobalSettings from "@/services/resources/GlobalSettings";
const serviceGlobalSettings = GlobalSettings.build();

// helpers
import Money from "@/mixins/money";
import Utils from "@/mixins/utils";
import { paymentMethodsString } from "../../config/paymentMethods";

export default {
  name: "Recrutamento",
  mixins: [Utils,Money],
  components: {
    ConfirmAffiliation,
  },
  data() {
    return {
      has_manager: false,
      error_product: false,
      hash: "",
      taxa_afiliado: 0,
      taxa_transacao: 0,
      loading: false,
      link_checkout: "",
      affiliation: null,
      solicitado: false,
      manager: "",
      existLast: false,
      product: {
        name: "...",
        max_boleto_installments: 0,
        description: "",
        amount: 0,
        type: null,
        period: 30,
        trial: null,
        quantity: 1,
        category_id: null,
        warranty: null,
        method: paymentMethodsString,
        affiliation: true,
        allow_proposal: 0,
        affiliation_approbation: 0,
        affiliation_public: 0,
        affiliation_proposal: 0,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        release: {},
        images: [],
        banner_checkout: [],
        metas: [],
        pixels: [],
        co_sellers: [],
      },
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
  },
  methods: {
    fetchGlobalSettings() {
      let data = {
        keys: "SELLER_FEE_TRANSACTION,AFFILIATE_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "AFFILIATE_FEE") {
            this.taxa_afiliado = +item.value;
          }
          if (item.key == "SELLER_FEE_TRANSACTION") {
            this.taxa_transacao = +item.value;
          }
        });
      });
    },
    changeSolicitacao() {
      this.solicitado = true;
    },
    toRouter(route) {
      this.$router.push(route);
    },
    toStore() {
      this.$router.push(`/vitrine`);
    },
    openConfirmAffiliation() {
      this.$bvModal.show("confirm-affiliation");
    },
    openSalesPage() {
      open(this.product.sales_page);
    },
    calComissao(preco, porcentagem) {
      const affiliate_fee = (preco * this.taxa_afiliado) / 100;

      const preco_taxado = preco - (this.taxa_transacao + affiliate_fee);

      const comissao = (preco_taxado * porcentagem) / 100;

      return comissao;
    },
    getProduct(id_product) {
      this.loading = true;
      let data = {
        id: id_product,
      };

      serviceProduct
        .read(data)
        .then((response) => {
          if (response.type == "COMMON") {
            this.product = response.data;
          } else if (response.type == "PROPOSAL") {
            this.product = response.data.product;
          }

          // link pagina de vendas
          this.product.sales_page = this.product.links.length
            ? this.product.links[0].url
            : "";
          // link do checkout
          this.link_checkout = this.baseURL + "/" + this.product.id;
          this.error_product = false;
        })
        .catch((err) => {
          console.log(err);
          this.error_product = true;
        })
        .finally(() => {
          // fim
          this.loading = false;
        });
    },
    getAffiliation(id_product) {
      if (!this.userID) {
        this.affiliation = null;
        return false;
      }
      let data = {
        id: `${this.userID}/${id_product}`,
      };

      serviceAffiliate
        .read(data)
        .then((resp) => {
          this.affiliation = resp;
          if (!Object.keys(this.affiliation).length) {
            this.affiliation = null;
            if (typeof this.$route.query.confirmOpen != "undefined") {
              this.openConfirmAffiliation();
            }
          } else {
            document.querySelector("body").style = "overflow: auto;";
          }
        })
        .catch((err) => {
          console.log(err);
          this.affiliation = null;
        });
    },
  },

  mounted() {
    this.fetchGlobalSettings();
    const query = this.$route.query;
    if (typeof query.p_id != "undefined") {
      this.getProduct(query.p_id);
      this.getAffiliation(query.p_id);
    }

    if (typeof query.store != "undefined") {
      this.existLast = true;
    }

    if (typeof query.gerente != "undefined") {
      this.has_manager = true;
      this.manager = query.gerente;
    }

    this.hash = this.$route.params.hash;
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.box-content {
  background: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 40px;
  padding-right: 80px;
  max-width: 968px;
}
.box-content + .box-content {
  margin-top: 25px;
}
.flex-content {
  display: grid;
  grid-template-columns: 275px 1fr;
}
/* imagem produto */

.foto-produto .img {
  max-width: 235px;
  width: 235px;
  /* height: 170px; */
  min-height: 170px;
  background: #f7f7f7;
  border-radius: 5px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* content header */
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f7f7f7;
}
.content-header h2 {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  max-width: 350px;
}
.content-header .automatica {
  display: flex;
  align-items: center;
}
.content-header .automatica img {
  margin-right: 10px;
}

/* valores */
.valores {
  display: grid;
  grid-template-columns: minmax(175px, auto) 1fr;
}
.valores p {
  margin-bottom: 10px;
}
.valores h5 {
  font-size: 20px;
  font-weight: 700;
}
.preco,
.comissao {
  padding: 15px;
  padding-left: 0;
}
.preco {
  padding-right: 50px;
}
.comissao {
  border-left: 1px solid #f7f7f7;
  padding-left: 50px;
}
.comissao h5 {
  /* color: #00af7b; */
  color: #002363;
}
.comissao span {
  margin-left: 7px;
  color: #81858e;
  font-size: 14px;
}
/* ações */
.action {
  margin: 20px auto;
}
.action .btn-afiliar {
  width: 100%;
}
.action p.comprar {
  margin-top: 15px;
  text-align: center;
}
.action p a {
  font-weight: 600;
  color: #2a63ab;
}

/* sobre o produto */

.sobre-produto h5 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
}

.descricao p + p {
  margin-top: 15px;
}
.link-vendas {
  margin-top: 25px;
}
.pg-vendas {
  display: flex;
  align-items: center;
}
.pg-vendas img {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 13px;
  }
  .flex-content,
  .valores {
    grid-template-columns: 1fr;
  }
  .content-header {
    margin-top: 30px;
    display: block;
  }
  .automatica {
    margin-top: 15px;
    /* max-width: 50%; */
  }
  .content-header h2 {
    font-size: 20px;
  }
  .valores {
    margin-top: 20px;
  }
  .preco,
  .comissao {
    padding: 10px 0;
    border: none;
  }
}
h6 {
  font-weight: 600;
}
.avaliacao {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 600;
  font-size: 16px;
  max-width: 60px;
}
.avaliacao span {
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
}

.voltar-vitrine {
  display: inline-block;
}
.voltar-vitrine a {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 15px;
  background: #f7f7f7;
  padding: 15px 20px;
  border-radius: 5px;
}

.voltar-vitrine img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}
</style>
